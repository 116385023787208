

.side{
  background-color: red;
  height: 800px;
}

	table {
			border-collapse: collapse;
			width: 1096px;
      color: rgb(107, 104, 104)
		}
		
		th, td {
			padding: 15px;
			text-align: left;
			border-bottom: 1px solid #ddd;
       font-size: 18px;
		}
		
		tr:hover {
			background-color: #f5f5f5;
		}
		
		th {
			background-color: #dce2e9;
			font-weight: bold;
      height: 40px;
     
      
		}

    td{
      font-size: 18px;
    }